import moment from "moment";
import { Bounce, toast } from "react-toastify";
import CONSTANTS from "./Constants";

const formatUnixDate = (
  unixDate: number | string,
  format: string = "MM/DD/YYYY"
) => {
  return moment.unix(Number(unixDate)).format(format);
};

const formatDateObject = (date: any, format: string = "DD-MM-YYYY") => {
  if (moment(date).isValid()) {
    return moment(date).format(format);
  } else {
    return "";
  }
};

const inputDateValidation = (dateValue: string) => {
  const isValid = moment(dateValue).isValid();

  if (isValid) {
    const year = moment(dateValue).year();

    if (year < 1900 || year > 2100) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const DateFunctions = {
  formatUnixDate,
  formatDateObject,
  inputDateValidation,
};

const warnToast = (message: string) => {
  toast.warn(message, {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

const errorToast = (message: string) => {
  toast.error(message, {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

const successToast = (message: string) => {
  toast.success(message, {
    position: "bottom-center",
    hideProgressBar: true,
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
};

const Toast = {
  warn: warnToast,
  error: errorToast,
  success: successToast,
};

const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return email.toLowerCase().match(emailRegex);
};

const isDocViewable = (docName: string) => {
  const isViewable =
    docName.includes("pdf") ||
    docName.includes("doc") ||
    docName.includes("png") ||
    docName.includes("jpg") ||
    docName.includes("jpeg");

  return isViewable;
};

const restrictToOnlyAlphabets = (e: any) => {
  if (!CONSTANTS.REGEX.VALID_NAME.test(e.key)) {
    e.preventDefault();
  }
};

const restrictAfterCertainDigits = (e: any, limit: number) => {
  const numOfDigits = e.target?.value.match(/\d/g)?.length;

  if (
    numOfDigits >= limit &&
    e.key !== "Backspace" &&
    e.key !== "ArrowLeft" &&
    e.key !== "ArrowRight"
  ) {
    e.preventDefault();
  }
};

const isLimitExceeded = (e: any, limit: number) => {
  let value = String(e.target.value);

  let pureNumber: any = "";

  let regex = /^[0-9]{0,100}(\.\d{0,10})?$/;

  if (value === "" || regex.test(value?.replace(/,/g, ""))) {
    pureNumber = value?.replace(/,/g, "");
  }

  const [preDecimal] = pureNumber.split(".");

  const numOfDigits = preDecimal.match(/\d/g)?.length;

  return Number(numOfDigits) > limit
  
};

const Validation = {
  email: validateEmail,
  isDocViewable,
  restrictToOnlyAlphabets,
  restrictAfterCertainDigits,
  isLimitExceeded
};

const storeString = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

const getString = (key: string) => {
  return localStorage.getItem(key);
};

const LOCAL_STORAGE_KEYS = {
  JWT_TOKEN: "JWT_TOKEN",
  USER_ID: "USER_ID",
  USER_NAME: "USER_NAME",
  Remaining_Attemts: "Remaining_Attemts",
};

const LocalStorage = {
  storeString,
  getString,
  KEYS: LOCAL_STORAGE_KEYS,
};

const getCountry = (countryCode: string | number) => {
  return String(countryCode);
};

const phoneNumberResolve = (number: string) => {
  const arr = number.split(" ");
  let phoneNumberData = {
    countryCode: 0,
    phoneNumber: "",
  };
  arr.forEach((item: string, index: number) => {
    if (index === 0) {
      phoneNumberData.countryCode = Number(item);
    } else {
      phoneNumberData.phoneNumber = phoneNumberData.phoneNumber + item;
    }
  });
  return phoneNumberData;
};

const geocodeResponseToCityState = (geocodeJSON: any) => {
  let locality: any = [];
  if (geocodeJSON.results.length) {
    let addressarr = geocodeJSON.results[0].address_components;
    addressarr.forEach((item: any) => {
      if (item.types.includes("locality")) {
        locality.city = item.long_name;
      } else if (item.types.includes("administrative_area_level_1")) {
        locality.stateName = item.long_name;
        locality.stateCode = item.short_name;
      } else if (item.types.includes("sublocality_level_1")) {
        locality.city = item.long_name;
      } else if (item.types.includes("country")) {
        locality.countryName = item.long_name;
        locality.countryCode = item.short_name;
      }
    });
  } else {
    console.log("error: no address components found");
  }
  return locality;
};

const getSkillandLicenses = (manualdata: any) => {
  let data: any = {};
  let skillarr: any = [];
  let cl_arr: any = [];
  if (manualdata.skills.length) {
    manualdata.skills?.forEach((item: any) => {
      skillarr.push(toCamelCase(item.skill));
    });
    let skills = skillarr.join(", ");
    data.skillsarr = skillarr;
    data.skills = skills;
  }
  if (manualdata.certificationsAndLicenses.length) {
    manualdata.certificationsAndLicenses?.forEach((item: any) => {
      cl_arr.push(item.certificationsAndLicense);
    });

    data.certificationsAndLicense = cl_arr;
  }
  return data;
};

const getJobType = (jobType: string) => {
  let jobTypePass = "";

  if (jobType === "R") {
    jobTypePass = "Right To Hire";
  } else if (jobType === "F") {
    jobTypePass = "Full Time";
  } else if (jobType === "C") {
    jobTypePass = "Contract";
  } else {
    jobTypePass = jobType;
  }

  return jobTypePass;
};

const getInterviewType = (interviewType: number) => {
  let InterviewTypePass = "";

  if (interviewType === 2) {
    InterviewTypePass = "Phone Interview";
  } else if (interviewType === 4) {
    InterviewTypePass = "In-person Interview";
  } else if (interviewType === 8) {
    InterviewTypePass = "Web Interview";
  } else if (interviewType === 16) {
    InterviewTypePass = "No Interview Required";
  }
  return InterviewTypePass;
};

const getModeofWork = (modeType: string) => {
  let modeTypePass = "";

  if (modeType === "remote") {
    modeTypePass = "Remote";
  } else if (modeType === "onsite") {
    modeTypePass = "Onsite";
  } else if (modeType === "hybrid") {
    modeTypePass = "Hybrid";
  }
  return modeTypePass;
};

const getJobDescriptionValue = (messageListPass: any) => {
  let ejd = "";
  messageListPass.map((listitem: any) => {
    if (listitem.jobTitle.length > 0) {
      ejd +=
        "<p><b>Job Title:</b><span>  " +
        toCamelCase(listitem.jobTitle) +
        "</span></p>";
    }
    if (listitem.industry.name.length > 0) {
      ejd +=
        "<p><b>Industry:</b><span>  " + listitem.industry.name + "</span></p>";
    }
    if (listitem.domain.name.length > 0) {
      ejd += "<p><b>Domain:</b><span>  " + listitem.domain.name + "</span></p>";
    }

    if (listitem.location.length > 0) {
      ejd += "<p><b>location:</b><span>  " + listitem.location + "</span></p>";
    }

    if (listitem.aboutClient.length > 0) {
      ejd += "<p><b>Client:</b></p>";
      ejd += "<p>" + listitem.aboutClient + "</p>";
    }
    if (listitem.description.length > 0) {
      ejd += "<p><b>Description:</b></p>";
      ejd += "<p>" + listitem.description + "</p>";
    }
    if (listitem.rolesAndResponsibilities.length > 0) {
      ejd += "<p><b>Roles and Responsibilities:</b></p>";
      ejd += "<ul>";
      listitem.rolesAndResponsibilities.map((item: any) => {
        ejd += "<p>" + item + "</p>";
      });
      ejd += "</ul>";
    }
    if (
      (listitem.education[0]?.degree != null ||
        listitem.education[0]?.degree != "") &&
      listitem.education.length > 0
    ) {
      ejd += "<p><b>Education:</b></p>";
      ejd += "<ul>";
      listitem.education.map((item: any) => {
        ejd += "<p>" + item.degree + "</p>";
      });
      ejd += "</ul>";
    }

    if (listitem.functionalSkills.length > 0) {
      ejd += "<p><b>Functional Skills:</b></p>";
      ejd += "<ul>";
      listitem.functionalSkills.map((item: any) => {
        ejd += "<p>" + item.skill + ": " + item.keywords?.join(", ") + "</p>";
      });
      ejd += "</ul>";
    }
    if (listitem.technicalSkills.length > 0) {
      ejd += "<p><b>Technical Skills:</b></p>";
      ejd += "<ul>";
      listitem.technicalSkills.map((item: any) => {
        ejd += "<p>" + item.skill + ": " + item.keywords?.join(", ") + "</p>";
      });
      ejd += "</ul>";
    }
    if (listitem.certifications.length > 0) {
      ejd += "<p><b>Certifications:</b></p>";
      ejd += "<ul>";
      listitem.certifications.map((item: any) => {
        ejd += "<p>" + item.name + "</p>";
      });
      ejd += "</ul>";
    }
  });
  return ejd;
};

const returnStringNotNull = (str: string | null | undefined | 0) => {
  if (str !== null && str !== undefined && str !== 0) {
    return str;
  } else return "";
};

const returnStringWithDollarNotNull = (str: string | null | undefined | 0) => {
  if (str !== null && str !== undefined && str !== 0 && str !== "") {
    return "$" + str;
  } else return "";
};

const formatNumberWithCommas = (value: any) => {
  if (!value) return "";
  value = parseFloat(value);
  const cleanValue = value.toString().replace(/,/g, "");
  const [whole, decimal] = cleanValue.split(".");
  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return decimal !== undefined
    ? `${formattedWhole}.${decimal}`
    : formattedWhole;
};

const formatNumberWithCommasForInput = (inputValue: any) => {
  if (!inputValue) return "";

  let value = parseFloat(inputValue);

  if (Number.isNaN(value)) {
    return;
  }

  const cleanValue = value.toString().replace(/,/g, "");

  const [whole, decimal] = cleanValue.split(".");

  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let returnVal = "";

  if (decimal) {
    returnVal = `${formattedWhole}.${decimal.substring(0, 2)}`;
  } else if (
    inputValue.charAt(inputValue.length - 1) === "." &&
    inputValue.length > 0
  ) {
    returnVal = formattedWhole + ".";
  } else if (
    inputValue.charAt(inputValue.length - 2) === "." &&
    Number(inputValue.charAt(inputValue.length - 1)) === 0
  ) {
    returnVal = formattedWhole + ".0";
  } else if (
    inputValue.charAt(inputValue.length - 3) === "." &&
    Number(inputValue.charAt(inputValue.length - 2)) === 0 &&
    Number(inputValue.charAt(inputValue.length - 1)) === 0
  ) {
    returnVal = formattedWhole + ".00";
  } else {
    returnVal = formattedWhole;
  }

  return returnVal;
};

const handleCurrencyChange = (value: any) => {
  let pureNumber: any = "";

  let regex = /^[0-9]{0,100}(\.\d{0,10})?$/;

  if (value === "" || regex.test(value?.replace(/,/g, ""))) {
    pureNumber = value.replace(/,/g, "");
  }

  const formattedNumber: any = formatNumberWithCommasForInput(pureNumber);

  return formattedNumber;
};

const modifyCurrentJob = (values: any, jobData: any) => {
  let ModifyJobOldNewValue =
    '<table style="width: 100%; font-size: 12px; font-family: arial, sans-serif;" border="0" width="100%" cellspacing="0" cellpadding="0"><thead><tr><th style="border: 1px solid #878787; padding: 5px; text-align: left; width: 33%; background: #03396c; color: #ffffff;" width="33%">Field</th><th style="border: 1px solid #878787; padding: 5px; text-align: left; width: 33%; background: #03396c; color: #ffffff;" width="33%">Old Value</th><th style="border: 1px solid #878787; padding: 5px; text-align: left; width: 33%; background: #03396c; color: #ffffff;" width="33%">New Value</th></tr></thead><tbody>';
  let jobFlag = false;
  console.log("jobData", jobData);
  Object.keys(jobData).forEach((item) => {
    let apidat = jobData[item];
    let editdata = values[item];
    let itemlable = "";
    switch (item) {
      case "jobTitle":
        itemlable = "Job Title:";
        break;
      case "numberOfOpenings":
        itemlable = "No. of Openings:";
        editdata = returnStringNotNull(editdata);
        apidat = returnStringNotNull(apidat);
        break;
      case "city":
        apidat =
          returnStringNotNull(apidat) +
          "," +
          jobData["stateCode"] +
          "," +
          jobData["countryCode"];
        itemlable = "City:";
        editdata =
          returnStringNotNull(editdata) +
          "," +
          values["stateCode"] +
          "," +
          values["countryCode"];

        break;
      case "zipCode":
        editdata = returnStringNotNull(editdata);
        itemlable = "Zip Code:";
        apidat = returnStringNotNull(apidat);
        break;
      case "jobDescription":
        editdata = returnStringNotNull(editdata);
        itemlable = "Job Description:";
        apidat = returnStringNotNull(apidat);
        break;
      case "billRate":
        itemlable = "Bill Rate:";
        let notnulleditdata = editdata;
        let notnullapidat = apidat;
        editdata = returnStringWithDollarNotNull(notnulleditdata);
        apidat = returnStringWithDollarNotNull(notnullapidat);
        break;
      case "salaryRange":
        let nnulleditdata = editdata;
        let nnullapidat = apidat;
        editdata = returnStringWithDollarNotNull(nnulleditdata);
        apidat = returnStringWithDollarNotNull(nnullapidat);
        itemlable = "Annual Salary:";
        break;
      case "interviewtype":
        itemlable = "Interview Mode:";
        apidat = Functions.ValueRetrieve.getInterviewType(apidat);
        editdata = Functions.ValueRetrieve.getInterviewType(editdata);
        break;
      case "jobType":
        itemlable = "Employment Type:";
        apidat = Functions.ValueRetrieve.getJobType(apidat);
        editdata = Functions.ValueRetrieve.getJobType(editdata);
        break;
      default:
        itemlable = item;
        break;
    }
    if (
      item === "skillarr" ||
      item === "countryCode" ||
      item === "stateCode" ||
      item === "cityId" ||
      item === "stateId" ||
      item === "countryId" ||
      item === "skillSet" ||
      item === "stateName" ||
      item === "countryName" ||
      item === "modeofwork" ||
      item === "jobStatus" ||
      item === "ModifyJobOldNewValue"
    ) {
      ModifyJobOldNewValue += "";
    } else if (item === "jobDescription") {
      apidat = apidat.replace(/(<([^>]+)>)/gi, "");
      editdata = editdata.replace(/(<([^>]+)>)/gi, "");
      if (apidat != editdata) {
        jobFlag = true;
        ModifyJobOldNewValue += "<tr>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; font-weight: bold; border-top: 0px; padding: 5px;'>" +
          itemlable +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          apidat +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          editdata +
          "</td>";
        ModifyJobOldNewValue += "</tr>";
      }
    } else {
      if (apidat != editdata) {
        jobFlag = true;
        ModifyJobOldNewValue += "<tr>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; font-weight: bold; border-top: 0px; padding: 5px;'>" +
          itemlable +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          apidat +
          "</td>";
        ModifyJobOldNewValue +=
          "<td style='border: 1px solid #878787; border-top: 0px; padding: 5px;'>" +
          editdata +
          "</td>";
        ModifyJobOldNewValue += "</tr>";
      }
    }
  });
  if (!jobFlag) {
    ModifyJobOldNewValue +=
      '<tr><td colspan="3" style="text-align: center;" >No modifications has been made.</td></tr>';
  }
  ModifyJobOldNewValue += "</tbody></table>";

  return ModifyJobOldNewValue;
};

const getYearTitle = (year: string) => {
  const numVal = Number(year);

  if (isNaN(numVal)) {
    return year;
  } else {
    if (numVal > 1) {
      return year + " Years";
    } else {
      return year + " Year";
    }
  }
};

const getJobListRowClassName = (postingDate: any) => {
  const postingDatePass = moment(postingDate);

  const currentDate = moment(new Date());

  let difference = Number(currentDate.diff(postingDatePass)) / 1000;

  if (difference < 120) {
    return "new-row-style";
  } else return "";
};

const getSortingKeyForApplications = (fieldName: string) => {
  let sortField = "";
  if (fieldName === "candidateName") {
    sortField = "candidateName";
  } else if (fieldName === "location") {
    sortField = "location";
  } else if (fieldName === "jobReference") {
    sortField = "jobId";
  } else if (fieldName === "status") {
    sortField = "status";
  } else if (fieldName === "experience") {
    sortField = "experience";
  } else if (fieldName === "jobTitle") {
    sortField = "jobtitle";
  }
  return sortField;
};

const getSortingKeyForUsers = (fieldName: string) => {
  let sortField = "";
  if (fieldName === "firstName") {
    sortField = "firstname";
  } else if (fieldName === "lastName") {
    sortField = "lastname";
  } else if (fieldName === "emailId") {
    sortField = "email";
  } else if (fieldName === "status") {
    sortField = "status";
  } else if (fieldName === "role") {
    sortField = "role";
  }
  return sortField;
};

const getLocation = (countryCode: string, stateCode: string, city: string) => {
  let locationPass = "";

  if (countryCode !== "" && countryCode !== null && countryCode !== undefined) {
    locationPass = countryCode;
  }

  if (stateCode !== "" && stateCode !== null && stateCode !== undefined) {
    locationPass = stateCode + ", " + locationPass;
  }

  if (city !== "" && city !== null && city !== undefined) {
    locationPass = city + ", " + locationPass;
  }

  if (locationPass === "") {
    return "-";
  } else return locationPass;
};
const checknav = (isexpand: boolean) => {
  let cknav = "hideexpandnav";
  if (isexpand) {
    cknav = "showexpandnav";
  }
  return cknav;
};

const checkicon = (isexpand: boolean) => {
  let ckicon = "RxHamburgerMenu";
  if (isexpand) {
    ckicon = "FaArrowLeftLong";
  }
  return ckicon;
};

const getParsedQuestions = (unprocessedQuestions: any) => {
  let processedQuestions: any = [];

  unprocessedQuestions?.map((item: any, index: number) => {
    let question = "Q" + item.questionid + ". " + item.question;
    let options: any = [];
    let checkboxOrRadio = item.choicetype === "checkbox";

    item.choices?.map((itemChild: any, indexChild: number) => {
      const selectedPass = item.selected_choice_ratings?.includes(
        item.choicerating?.[indexChild]
      );

      options.push({
        text: itemChild,
        selected: selectedPass,
      });
    });

    if (!checkboxOrRadio) {
      let selectFound = false;
      let optionsPass: any = [];

      options.map((item: any) => {
        if (selectFound) {
          optionsPass.push({
            text: item.text,
            selected: false,
          });
        } else {
          optionsPass.push({
            text: item.text,
            selected: item.selected,
          });
        }

        if (item.selected) {
          selectFound = true;
        }
      });

      options = optionsPass;
    }

    processedQuestions.push({
      question,
      options,
      checkboxOrRadio,
    });
  });

  return processedQuestions;
};

const parseJson = (prejson: any) => {
  try {
    let returnVal = JSON.parse(prejson);

    return returnVal;
  } catch (e) {
    return false;
  }
};

const specialchatcheck = (str: any) => {
  let specialChars = ".-+#,/";
  let seprator = null;
  for (let i in str) {
    if (specialChars.indexOf(str[i]) !== -1) {
      seprator = str[i];
    }
  }
  let parts = str.split(seprator);
  if (parts[1]) {
    parts[1] =
      parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase();
  }
  let result = parts?.join(seprator);
  return result;
};
const prepositioncheck = (str: any) => {
  let pre = [
    "and",
    "or",
    "above",
    "at",
    "below",
    "beside",
    "between",
    "by",
    "on",
    "over",
    "out",
    "under",
    "in",
    "after",
    "before",
    "during",
    "since",
    "until",
    "across",
    "around",
    "down",
    "into",
    "past",
    "through",
    "to",
    "toward",
    "about",
    "as",
    "for",
    "from",
    "of",
    "with",
    "a",
    "an",
    "the",
  ];
  let upstr = str.split(" ");
  let newarr = [];
  for (let text of upstr) {
    if (pre.includes(text.toLowerCase()) && upstr.indexOf(text) != 0) {
      newarr.push(text.toLowerCase());
    } else {
      newarr.push(casetransform(text));
    }
  }
  return newarr.join(" ");
};
const casetransform = (text: any) => {
  let transformtext = "";
  if (text === text.toUpperCase()) {
    transformtext = text;
  } else {
    transformtext = text.charAt(0).toUpperCase() + text.slice(1);
  }
  transformtext = specialchatcheck(transformtext);

  return transformtext;
};

const toCamelCase = (str: any) => {
  if (str === str?.toUpperCase()) {
    return str;
  } else {
    return prepositioncheck(str);
  }
};

const removeComma = (strNum: string) => {
  return parseFloat(strNum.replace(/,/g, ""));
};

const ValueRetrieve = {
  country: getCountry,
  phoneNumberResolve,
  getJobType,
  getModeofWork,
  getInterviewType,
  geocodeResponseToCityState,
  getSkillandLicenses,
  getJobDescriptionValue,
  modifyCurrentJob,
  getYearTitle,
  getJobListRowClassName,
  getSortingKeyForApplications,
  getLocation,
  checknav,
  checkicon,
  getParsedQuestions,
  parseJson,
  toCamelCase,
  getSortingKeyForUsers,
  formatNumberWithCommas,
  formatNumberWithCommasForInput,
  handleCurrencyChange,
  removeComma,
};

const removeItemOnce = (arr: any, value: any) => {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

const ArrayFunctions = {
  removeItemOnce,
};

const booleanAnd = (
  firstBool: boolean | undefined,
  secondBool: boolean | undefined
) => {
  return firstBool && secondBool;
};

const booleanAndString = (
  bool: boolean | undefined,
  str: string | undefined
) => {
  return bool && str;
};

const Sonar = {
  booleanAnd,
  booleanAndString,
  returnStringNotNull,
  returnStringWithDollarNotNull,
};

const fileDownload = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.setAttribute("target", "_blank");

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
};

const Download = {
  fileDownload,
};

const Functions = {
  DateFunctions,
  Toast,
  Validation,
  LocalStorage,
  ValueRetrieve,
  ArrayFunctions,
  Sonar,
  Download,
};

export default Functions;
