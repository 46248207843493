// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, ButtonBase } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";
import Paths from "../../routes/Paths";

// Component Imports
import FilterPopupProjects from "./FilterPopupProjects";
import ProjectList from "./ProjectList";
import Breadcrumbs from "../../common/Breadcrumbs";
import FilterTile from "../../common/FilterTile";
import Text from "../../common/Text";
import EndProjectPopup from "./EndProjectPopup";
import ChangeRatePopup from "./ChangeRatePopup";

interface ProjectListingProps {
  isTesting?: boolean;
}

const ProjectListing: FC<ProjectListingProps> = (props) => {
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [selectedFiltersForRequest, setSelectedFiltersForRequest] =
    useState<any>([]);

  const [selectedFilter, setSelectedFilter] = useState<any>("");

  const [searchText, setSearchText] = useState("");

  const [dataLoading, setDataLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [sortModel, setSortModel] = useState<any>();
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (props.isTesting) {
      console.log(setSelectedFiltersForRequest);
      console.log(pagination);
      handleClick({});
      removeFilter("");
      onClearAllFilters();
      onApply();
    }
  }, []);

  const handleClick = (event: any) => {
    if (filtersOpen) {
      setAnchorEl(null);
      setFiltersOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setFiltersOpen(true);
    }
  };

  const removeFilter = (filter: string) => {
    console.log("Code Reachable! Sonar");
  };
  const onClearAllFilters = () => {
    console.log("Code Reachable! Sonar");
  };
  const onApply = () => {
    console.log("Code Reachable! Sonar");
  };

  return (
    <Box
      data-testid="project-listing-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          pt: 2,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
            mt: 1,
            mb: 0.5,
          }}
          path={[
            { path: Paths.APP + Paths.APPLICATIONS, name: "Home" },
            { name: "Projects" },
          ]}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterPopupProjects
            isExpanded={filtersOpen}
            setIsExpanded={setFiltersOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            dataLoading={dataLoading}
            setDataLoading={setDataLoading}
            onApply={onApply}
            anchorEl={anchorEl}
          />
          <ButtonBase
            data-testid="filter-toggle-btn"
            onClick={(ev) => handleClick(ev)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              py: 0.5,
              mr: 2,
            }}
          >
            <Text fontWeight={700} fontSize={16}>
              Filters
            </Text>
            <FaAngleDown
              size={18}
              style={{
                marginTop: 2,
              }}
            />
          </ButtonBase>
          {selectedFiltersForRequest.map((item: any) => (
            <FilterTile
              key={item}
              title={item}
              onClick={() => removeFilter(item)}
            />
          ))}
          {selectedFiltersForRequest.length > 0 && (
            <ButtonBase
              onClick={onClearAllFilters}
              sx={{
                p: 0.5,
              }}
              data-testid="clear-all-filters-btn"
            >
              <Text fontWeight={700} fontSize={16}>
                Clear All Filters
              </Text>
            </ButtonBase>
          )}
        </Box>
      </Box>
      <ProjectList
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
        sortModel={sortModel}
        setSortModel={setSortModel}
        setPagination={setPagination}
        pageIndex={0}
        pageSize={0}
        tableData={[]}
      />
      <EndProjectPopup
        open={false}
        setOpen={() => {}}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <ChangeRatePopup
        open={false}
        setOpen={() => {}}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </Box>
  );
};

export default ProjectListing;
