// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import {
  Box,
  ButtonBase,
  InputAdornment,
  Paper,
  Popover,
  TextField,
} from "@mui/material";
import { FaRegUser } from "react-icons/fa";
import { BiBriefcase } from "react-icons/bi";
import { IoMdSearch } from "react-icons/io";

// Functional Imports
import { useNavigate } from "react-router-dom";
import Functions from "../../utils/Functions";
import Images from "../../utils/Images";
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";
import Paths from "../../routes/Paths";
import API from "../../api/API";

// Component Imports
import HoverActionDashboard from "./HoverActionDashboard";
import CreateJobButton from "./CreateJobButton";
import HoverActionJobs from "../AllJobs/HoverActionJobs";
import DashboardTile from "./DashboardTile";
import ResumePopup from "../../common/ResumePopup";
import ImageBox from "../../common/ImageBox";
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import ResumeDocPopup from "../../common/ResumeDocPopup";

interface DashboardProps {
  isTesting?: boolean;
}

const Dashboard: FC<DashboardProps> = (props) => {
  const navigate = useNavigate();

  const [isExpanded, setExpanded] = useState(false);

  const [hoverActionsEnabled, setHoverActionsEnabled] = useState(false);
  const [hoverType, setHoverType] = useState("");

  const [hoverJobId, setHoverJobId] = useState("");

  const [hoverApplicationId, setHoverApplicationId] = useState("");
  const [hoverDocId, setHoverDocId] = useState("");
  const [hoverDocName, setHoverDocName] = useState("");

  const [resumeOpen, setResumeOpen] = useState(false);
  const [resumeLink, setResumeLink] = useState("");
  const [resumeDocOpen, setResumeDocOpen] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [applications, setApplications] = useState([]);
  const [jobs, setJobs] = useState([]);

  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      getDashboardData();
      handleClick({} as React.MouseEvent<HTMLElement>);
      handlePopoverClose();
      handleExpanded();
      onResumeOpen("", true, "");
      handleJobTileClick({}, {});
      handleJobClick();
      handleApplicationTileClick({}, {});
      handleApplicationClick();
      handleUsersClick();
    }
  }, []);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setDataLoading(true);

    const [applicationData, jobData] = await Promise.all([
      API.getRecentApplications(),
      API.getRecentJobs(),
    ]);

    let applicationPass = applicationData.entityList?.map((item: any) => ({
      topTitle: item.candidateName,
      bottomTitle: Functions.DateFunctions.formatDateObject(
        item.createdDate,
        "MM/DD/YYYY"
      ),
      otherDetails: item,
    }));

    let jobsPass = jobData.entityList?.map((item: any) => ({
      topTitle: item.jobTitle,
      bottomTitle: item.id,
      otherDetails: item,
    }));

    setApplications(applicationPass);
    setJobs(jobsPass);
    setDataLoading(false);
    setExpanded(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hoverActionsEnabled) {
      setAnchorEl(null);
      setHoverActionsEnabled(false);
    } else {
      setAnchorEl(event.currentTarget);
      setHoverActionsEnabled(true);
    }
  };

  const handlePopoverClose = () => setHoverActionsEnabled(false);

  const handleExpanded = () => setExpanded(!isExpanded);

  const id = hoverActionsEnabled ? "simple-popover" : undefined;

  const userName = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.USER_NAME
  );

  const onResumeOpen = (link: any, isDoc: boolean, docName: string) => {
    setHoverActionsEnabled(false);
    if (isDoc) {
      setResumeDocOpen(true);
    } else {
      setResumeOpen(true);
    }
    setDocumentName(docName);
    setResumeLink(link);
  };

  const handleJobTileClick = (ev: any, otherDetails: any) => {
    setHoverJobId(otherDetails.id);
    setHoverType(ENUMS.DASHBOARD_ACTION_TYPES.JOBS);
    handleClick(ev);
  };

  const handleJobClick = () => navigate(Paths.JOBS);
  const handleUserClick = () => navigate(Paths.USERS);

  const handleApplicationTileClick = (ev: any, otherDetails: any) => {
    setHoverType(ENUMS.DASHBOARD_ACTION_TYPES.APPLICATIONS);
    setHoverApplicationId(otherDetails.id);
    setHoverDocId(otherDetails.candidateDocId);
    setHoverDocName(otherDetails.resumeFile);
    handleClick(ev);
  };

  const handleApplicationClick = () => navigate(Paths.APPLICATIONS);

  const handleUsersClick = () => navigate(Paths.USERS);

  return (
    <Box
      data-testid="dashboard"
      sx={{
        backgroundColor: Colors.LightWhite,
      }}
    >
      {/* <Header isdashboard /> */}
      <Box
        sx={{
          pl: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize={28} fontWeight={700}>
              Hello,
            </Text>
            <Text fontSize={28} color={Colors.Grey6}>
              {userName + "!"}
            </Text>
          </Box>
          <Box>
            <TextField
              placeholder="Search"
              size="small"
              variant="outlined"
              sx={{
                height: 35,
                borderRadius: 0,
                paddingRight: 0,
                marginRight: 1.5,
                fontFamily: "Roboto",
                width: 360,
                backgroundColor: Colors.White,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IoMdSearch size={20} />
                  </InputAdornment>
                ),
              }}
            />
            <CreateJobButton />
          </Box>
        </Box>

        <Paper
          sx={{
            transition: "height 0.1s",
            height: isExpanded ? 420 : 150,
            boxShadow: "0px 6px 12px 0px " + Colors.LightBlue8,
            backgroundColor: Colors.White,
            mt: 2,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonBase
              onClick={handleExpanded}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                gap: 0.5,
                mr: -1,
                mt: -1,
              }}
            >
              {isExpanded && (
                <ImageBox
                  src={Images.DoubleUpBlueIcon}
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                />
              )}
              {!isExpanded && (
                <ImageBox
                  src={Images.DoubleDownBlueIcon}
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                />
              )}
              <Text fontSize={12} fontWeight={700} color={Colors.Blue7}>
                {isExpanded ? "Collapse" : "Expand"}
              </Text>
            </ButtonBase>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DashboardTile
              isExpanded={isExpanded}
              borderColorTop={Colors.Orange2}
              topIcon={
                <ImageBox
                  src={Images.BriefcaseYellowIcon}
                  sx={{
                    height: 20,
                    width: 20,
                    m: 1,
                    ml: 0,
                  }}
                />
              }
              title="Jobs"
              subTitle="Recent Jobs"
              listIcon={<BiBriefcase size={20} />}
              entries={jobs}
              id={id}
              handleClick={handleJobTileClick}
              hoverType={ENUMS.DASHBOARD_ACTION_TYPES.JOBS}
              onMainClick={handleJobClick}
              emptyText={"No Recent Jobs"}
              dataLoading={dataLoading}
            />
            <DashboardTile
              isExpanded={isExpanded}
              borderColorTop={Colors.Blue3}
              topIcon={
                <ImageBox
                  src={Images.DocumentBlueIcon}
                  sx={{
                    height: 20,
                    width: 20,
                    m: 1,
                    ml: 0,
                  }}
                />
              }
              title="Applications"
              subTitle="Recent Submissions"
              listIcon={
                <ImageBox
                  src={Images.DocumentUserIcon}
                  sx={{
                    height: 20,
                    width: 20,
                    m: 1,
                  }}
                />
              }
              entries={applications}
              id={id}
              hoverType={ENUMS.DASHBOARD_ACTION_TYPES.APPLICATIONS}
              handleClick={handleApplicationTileClick}
              onMainClick={handleApplicationClick}
              emptyText={"No Recent Applications"}
              dataLoading={dataLoading}
            />

            <DashboardTile
              isExpanded={isExpanded}
              borderColorTop={Colors.Blue4}
              topIcon={
                <ImageBox
                  src={Images.TimesheetBlueIcon}
                  sx={{
                    height: 20,
                    width: 20,
                    m: 1,
                    ml: 0,
                  }}
                />
              }
              title="Timesheet"
              subTitle="Pending Approval"
              listIcon={<FaRegUser size={18} />}
              entries={[]}
              id={id}
              handleClick={handleClick}
              emptyText={"No Timesheets"}
              emptyImageOrText={true}
              emptyImage={
                <ImageBox
                  src={Images.NoPendingApproval}
                  sx={{
                    width: 150,
                    m: 1,
                    mt: 3,
                  }}
                />
              }
              dataLoading={dataLoading}
            />
          </Box>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            px: 2,
          }}
        >
          <DashboardTile
            isExpanded={false}
            borderColorTop={Colors.Purple}
            sx={{
              boxShadow: "0px 6px 12px 0px " + Colors.LightBlue8,
            }}
            topIcon={
              <ImageBox
                src={Images.FolderPurpleIcon}
                sx={{
                  height: 20,
                  width: 20,
                  m: 1,
                  ml: 0,
                }}
              />
            }
            title="Projects"
          />
          <DashboardTile
            isExpanded={false}
            borderColorTop={Colors.Voilet}
            sx={{
              boxShadow: "0px 6px 12px 0px " + Colors.LightBlue8,
            }}
            topIcon={
              <ImageBox
                src={Images.UserVoiletIcon}
                sx={{
                  height: 20,
                  width: 20,
                  m: 1,
                  ml: 0,
                }}
              />
            }
            title="Workers"
          />
          <DashboardTile
            isExpanded={false}
            borderColorTop={Colors.Orange3}
            sx={{
              boxShadow: "0px 6px 12px 0px " + Colors.LightBlue8,
            }}
            topIcon={
              <ImageBox
                src={Images.UserSettingsOrangeIcon}
                sx={{
                  height: 20,
                  width: 20,
                  m: 1,
                  ml: 0,
                }}
              />
            }
            onMainClick={handleUserClick}
            title="Users"
          />
        </Box>
      </Box>
      <Popover
        id={id}
        open={hoverActionsEnabled}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: -170,
        }}
      >
        {hoverType === ENUMS.DASHBOARD_ACTION_TYPES.JOBS && (
          <HoverActionJobs id={hoverJobId} />
        )}
        {hoverType === ENUMS.DASHBOARD_ACTION_TYPES.APPLICATIONS && (
          <HoverActionDashboard
            id={hoverApplicationId}
            docId={hoverDocId}
            docName={hoverDocName}
            onResumeOpen={onResumeOpen}
            handlePopoverClose={handlePopoverClose}
            setDataLoading={setDataLoading}
          />
        )}
      </Popover>
      {dataLoading && <Loader />}
      <ResumePopup
        open={resumeOpen}
        setOpen={setResumeOpen}
        resumeLink={resumeLink}
      />
      <ResumeDocPopup
        open={resumeDocOpen}
        setOpen={setResumeDocOpen}
        documentLink={resumeLink}
        documentName={documentName}
      />
    </Box>
  );
};

export default Dashboard;
