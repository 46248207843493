// React Imports
import React, { FC, useEffect, useState } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Box, IconButton, SxProps, Tooltip } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { MdOutlineFileDownload } from "react-icons/md";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import EmptyList from "../Applications/EmptyList";
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import API from "../../api/API";
import Functions from "../../utils/Functions";
import ResumeDocPopup from "../../common/ResumeDocPopup";
import ResumePopup from "../../common/ResumePopup";
import Toasts from "../../utils/Toasts";

interface DocumentsListProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableData?: any;
  tableLoading?: boolean;

  testId?: string;
  isTesting?: boolean;
}

const DocumentsList: FC<DocumentsListProps> = (props) => {
  const [documentDocOpen, setDocumentDocOpen] = useState(false);
  const [documentPdfOpen, setDocumentPdfOpen] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    if (props.isTesting) {
      onView(0, "doc", "0");
      onView(0, "0", "0");
      onDownload(0, "0");
    }
  }, []);

  const onView = (
    docId: string | number,
    docName: string,
    orgDocName: string
  ) => {
    const isViewable = Functions.Validation.isDocViewable(docName);

    if (!isViewable) {
      Toasts.error("No Preview Available");
      return;
    }

    const isDoc = docName.includes("doc");

    API.getSubmissionDocument(Number(docId))
      .then((response) => {
        let linkToPdf = String(response.entity);

        if (!linkToPdf.includes("https")) {
          linkToPdf = response.entity?.replace("http", "https");
        }

        setDocumentLink(linkToPdf);
        setDocumentName(orgDocName);
        if (isDoc) {
          setDocumentDocOpen(true);
        } else {
          setDocumentPdfOpen(true);
        }
      })
      .catch((e) => {});
  };

  const onDownload = (docId: string | number, docName: string) => {
    API.getSubmissionDocument(Number(docId))
      .then((response) => {
        let linkToPdf = String(response.entity);

        if (!linkToPdf.includes("https")) {
          linkToPdf = response.entity?.replace("http", "https");
        }

        Functions.Download.fileDownload(linkToPdf, docName);
      })
      .catch((e) => {});
  };

  const applicationListColumns = [
    ...columns,
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            ml: -1,
          }}
        >
          <Tooltip arrow title="View">
            <IconButton
              onClick={() => {
                onView(
                  params.row?.dMSDocId,
                  params.row?.fileName,
                  params.row?.dMSDocumentName
                );
              }}
            >
              <MdOutlineRemoveRedEye size={20} color={Colors.Black1} />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title="Download">
            <IconButton
              onClick={() => {
                onDownload(params.row?.dMSDocId, params.row?.fileName);
              }}
            >
              <MdOutlineFileDownload size={20} color={Colors.Black1} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      minWidth: 60,
      flex: 0.5,
      ...commonColumnProps,
      sortable: false,
    },
  ];

  return (
    <Box data-testid="documents-list">
      {props.tableData?.length === 0 && (
        <EmptyList
          sx={{
            p: 8,
          }}
        />
      )}
      {props.tableData?.length > 0 && (
        <DataGrid
          loading={props.tableLoading}
          disableVirtualization={props.disableVirtualization}
          data-testid={props.testId}
          slotProps={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          sx={{
            backgroundColor: Colors.White,
            width: "calc(79.5vw - 40px)",
            marginTop: 1.5,
            height: 400,
            ml: 4,
            ...props.sx,
          }}
          onPaginationModelChange={setPagination}
          paginationModel={pagination}
          pageSizeOptions={[10, 20, 50, 100]}
          rows={props.tableData}
          getRowId={(row) => row.dMSDocId}
          columns={applicationListColumns}
          rowSelection={false}
        />
      )}
      <ResumeDocPopup
        open={documentDocOpen}
        setOpen={setDocumentDocOpen}
        documentLink={documentLink}
        documentName={documentName}
      />
      <ResumePopup
        open={documentPdfOpen}
        setOpen={setDocumentPdfOpen}
        resumeLink={documentLink}
      />
    </Box>
  );
};

export default DocumentsList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
  sortable: false,
  headerClassName: "documents-list-header",
};

const columns: GridColDef[] = [
  {
    field: "documentName",
    headerName: "Document Name",
    ...headerText,
    minWidth: 140,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "startDate",
    headerName: "Start Date",
    ...headerText,
    valueGetter: (params) => {
      if (params.row?.startDate === "") {
        return "N/A";
      } else return params.row?.startDate;
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "endDate",
    headerName: "End Date",
    ...headerText,
    valueGetter: (params) => {
      if (params.row?.isNoExpiryOpenEnded) {
        return "Does not expire";
      } else if (params.row?.endDate === "") {
        return "N/A";
      } else {
        return params.row?.endDate;
      }
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
];
